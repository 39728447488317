export const AutoFormatInput = () => import('../../components/AutoFormatInput.vue' /* webpackChunkName: "components/auto-format-input" */).then(c => wrapFunctional(c.default || c))
export const CreateAndCancelButton = () => import('../../components/CreateAndCancelButton.vue' /* webpackChunkName: "components/create-and-cancel-button" */).then(c => wrapFunctional(c.default || c))
export const CustomTooltip = () => import('../../components/CustomTooltip.vue' /* webpackChunkName: "components/custom-tooltip" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const DragAndDropFile = () => import('../../components/DragAndDropFile.vue' /* webpackChunkName: "components/drag-and-drop-file" */).then(c => wrapFunctional(c.default || c))
export const Editor = () => import('../../components/Editor.vue' /* webpackChunkName: "components/editor" */).then(c => wrapFunctional(c.default || c))
export const GoBack = () => import('../../components/GoBack.vue' /* webpackChunkName: "components/go-back" */).then(c => wrapFunctional(c.default || c))
export const ImageUploadDragDrop = () => import('../../components/ImageUploadDragDrop.vue' /* webpackChunkName: "components/image-upload-drag-drop" */).then(c => wrapFunctional(c.default || c))
export const InputLabel = () => import('../../components/InputLabel.vue' /* webpackChunkName: "components/input-label" */).then(c => wrapFunctional(c.default || c))
export const JobsId = () => import('../../components/JobsId.vue' /* webpackChunkName: "components/jobs-id" */).then(c => wrapFunctional(c.default || c))
export const Language = () => import('../../components/Language.vue' /* webpackChunkName: "components/language" */).then(c => wrapFunctional(c.default || c))
export const LogcDialog = () => import('../../components/LogcDialog.vue' /* webpackChunkName: "components/logc-dialog" */).then(c => wrapFunctional(c.default || c))
export const NewsId = () => import('../../components/NewsId.vue' /* webpackChunkName: "components/news-id" */).then(c => wrapFunctional(c.default || c))
export const NuxtImage = () => import('../../components/NuxtImage.vue' /* webpackChunkName: "components/nuxt-image" */).then(c => wrapFunctional(c.default || c))
export const PhoneNumberInput = () => import('../../components/PhoneNumberInput.vue' /* webpackChunkName: "components/phone-number-input" */).then(c => wrapFunctional(c.default || c))
export const SearchBy = () => import('../../components/SearchBy.vue' /* webpackChunkName: "components/search-by" */).then(c => wrapFunctional(c.default || c))
export const SearchByEmployees = () => import('../../components/SearchByEmployees.vue' /* webpackChunkName: "components/search-by-employees" */).then(c => wrapFunctional(c.default || c))
export const TitleAndSubtitle = () => import('../../components/TitleAndSubtitle.vue' /* webpackChunkName: "components/title-and-subtitle" */).then(c => wrapFunctional(c.default || c))
export const UserDetail = () => import('../../components/UserDetail.vue' /* webpackChunkName: "components/user-detail" */).then(c => wrapFunctional(c.default || c))
export const VueEditorComponent = () => import('../../components/VueEditorComponent.vue' /* webpackChunkName: "components/vue-editor-component" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalDatePicker = () => import('../../components/internal-portal/DatePicker.vue' /* webpackChunkName: "components/internal-portal-date-picker" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalTitleAndSubtitle = () => import('../../components/internal-portal/TitleAndSubtitle.vue' /* webpackChunkName: "components/internal-portal-title-and-subtitle" */).then(c => wrapFunctional(c.default || c))
export const LanguageStringsSearchText = () => import('../../components/language-strings/SearchText.vue' /* webpackChunkName: "components/language-strings-search-text" */).then(c => wrapFunctional(c.default || c))
export const OperatorsUpdateEmployeeStatus = () => import('../../components/operators/UpdateEmployeeStatus.vue' /* webpackChunkName: "components/operators-update-employee-status" */).then(c => wrapFunctional(c.default || c))
export const OperatorsUpdateStatus = () => import('../../components/operators/UpdateStatus.vue' /* webpackChunkName: "components/operators-update-status" */).then(c => wrapFunctional(c.default || c))
export const UsersResetGoogleAuth = () => import('../../components/users/ResetGoogleAuth.vue' /* webpackChunkName: "components/users-reset-google-auth" */).then(c => wrapFunctional(c.default || c))
export const UsersUpdateStatus = () => import('../../components/users/UpdateStatus.vue' /* webpackChunkName: "components/users-update-status" */).then(c => wrapFunctional(c.default || c))
export const VuetifyPhoneInput = () => import('../../components/vuetify/PhoneInput.vue' /* webpackChunkName: "components/vuetify-phone-input" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalHrMonitoringAddOperator = () => import('../../components/internal-portal/hr-monitoring/AddOperator.vue' /* webpackChunkName: "components/internal-portal-hr-monitoring-add-operator" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalHrMonitoringEditOperator = () => import('../../components/internal-portal/hr-monitoring/EditOperator.vue' /* webpackChunkName: "components/internal-portal-hr-monitoring-edit-operator" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalLcmInternalHrCreateDepartment = () => import('../../components/internal-portal/lcm-internal-hr/CreateDepartment.vue' /* webpackChunkName: "components/internal-portal-lcm-internal-hr-create-department" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalLcmInternalHrDeleteDepartmentButton = () => import('../../components/internal-portal/lcm-internal-hr/DeleteDepartmentButton.vue' /* webpackChunkName: "components/internal-portal-lcm-internal-hr-delete-department-button" */).then(c => wrapFunctional(c.default || c))
export const InternalPortalUserManagementCreateIpWhitelist = () => import('../../components/internal-portal/user-management/CreateIpWhitelist.vue' /* webpackChunkName: "components/internal-portal-user-management-create-ip-whitelist" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

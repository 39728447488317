import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _71f208b0 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _09c8fb80 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _49756f1d = () => interopDefault(import('../pages/internal-portal/index.vue' /* webpackChunkName: "pages/internal-portal/index" */))
const _46490722 = () => interopDefault(import('../pages/language-strings/index.vue' /* webpackChunkName: "pages/language-strings/index" */))
const _58823707 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _b510614c = () => interopDefault(import('../pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _5eeff84a = () => interopDefault(import('../pages/office-website-cms/index.vue' /* webpackChunkName: "pages/office-website-cms/index" */))
const _20a85f25 = () => interopDefault(import('../pages/permissions/index.vue' /* webpackChunkName: "pages/permissions/index" */))
const _6cb34029 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _40145cf1 = () => interopDefault(import('../pages/about-us/about/index.vue' /* webpackChunkName: "pages/about-us/about/index" */))
const _625e09f0 = () => interopDefault(import('../pages/about-us/mission/index.vue' /* webpackChunkName: "pages/about-us/mission/index" */))
const _484730f6 = () => interopDefault(import('../pages/about-us/organization-chart/index.vue' /* webpackChunkName: "pages/about-us/organization-chart/index" */))
const _0f02b39a = () => interopDefault(import('../pages/about-us/vision/index.vue' /* webpackChunkName: "pages/about-us/vision/index" */))
const _17f94fe4 = () => interopDefault(import('../pages/admin/create.vue' /* webpackChunkName: "pages/admin/create" */))
const _0c5d6cc7 = () => interopDefault(import('../pages/contact-us/faq/index.vue' /* webpackChunkName: "pages/contact-us/faq/index" */))
const _42a26f93 = () => interopDefault(import('../pages/contact-us/general-inquiry/index.vue' /* webpackChunkName: "pages/contact-us/general-inquiry/index" */))
const _47fdad02 = () => interopDefault(import('../pages/contact-us/whistle-blower/index.vue' /* webpackChunkName: "pages/contact-us/whistle-blower/index" */))
const _378fcf9b = () => interopDefault(import('../pages/home/article/index.vue' /* webpackChunkName: "pages/home/article/index" */))
const _0ab855f4 = () => interopDefault(import('../pages/home/article-category/index.vue' /* webpackChunkName: "pages/home/article-category/index" */))
const _52d6db79 = () => interopDefault(import('../pages/home/menubar/index.vue' /* webpackChunkName: "pages/home/menubar/index" */))
const _6be1cb1c = () => interopDefault(import('../pages/home/slider/index.vue' /* webpackChunkName: "pages/home/slider/index" */))
const _1754fab1 = () => interopDefault(import('../pages/internal-portal/lcm-internal-hr/index.vue' /* webpackChunkName: "pages/internal-portal/lcm-internal-hr/index" */))
const _336a7dcf = () => interopDefault(import('../pages/internal-portal/operator-financial-report/index.vue' /* webpackChunkName: "pages/internal-portal/operator-financial-report/index" */))
const _1f47ebec = () => interopDefault(import('../pages/internal-portal/operator-hr-monitoring/index.vue' /* webpackChunkName: "pages/internal-portal/operator-hr-monitoring/index" */))
const _3ba18c1f = () => interopDefault(import('../pages/internal-portal/user-activity/index.vue' /* webpackChunkName: "pages/internal-portal/user-activity/index" */))
const _7f42c713 = () => interopDefault(import('../pages/internal-portal/user-management/index.vue' /* webpackChunkName: "pages/internal-portal/user-management/index" */))
const _02cfddfe = () => interopDefault(import('../pages/join-us/career/index.vue' /* webpackChunkName: "pages/join-us/career/index" */))
const _3f31203a = () => interopDefault(import('../pages/join-us/project-bidding/index.vue' /* webpackChunkName: "pages/join-us/project-bidding/index" */))
const _aae7b048 = () => interopDefault(import('../pages/language-strings/create.vue' /* webpackChunkName: "pages/language-strings/create" */))
const _2633229e = () => interopDefault(import('../pages/language/create.vue' /* webpackChunkName: "pages/language/create" */))
const _7b8a9ef0 = () => interopDefault(import('../pages/news/news/index.vue' /* webpackChunkName: "pages/news/news/index" */))
const _1c17d039 = () => interopDefault(import('../pages/permissions/create.vue' /* webpackChunkName: "pages/permissions/create" */))
const _0d076918 = () => interopDefault(import('../pages/policy-and-regulation/approved-license/index.vue' /* webpackChunkName: "pages/policy-and-regulation/approved-license/index" */))
const _65bb438a = () => interopDefault(import('../pages/policy-and-regulation/policy/index.vue' /* webpackChunkName: "pages/policy-and-regulation/policy/index" */))
const _7d2207b3 = () => interopDefault(import('../pages/policy-and-regulation/regulation/index.vue' /* webpackChunkName: "pages/policy-and-regulation/regulation/index" */))
const _55fa7f62 = () => interopDefault(import('../pages/projects/create.vue' /* webpackChunkName: "pages/projects/create" */))
const _51690fb5 = () => interopDefault(import('../pages/users/create.vue' /* webpackChunkName: "pages/users/create" */))
const _f229158c = () => interopDefault(import('../pages/about-us/about/update.vue' /* webpackChunkName: "pages/about-us/about/update" */))
const _11177ece = () => interopDefault(import('../pages/about-us/mission/create.vue' /* webpackChunkName: "pages/about-us/mission/create" */))
const _2f598ef0 = () => interopDefault(import('../pages/about-us/organization-chart/create.vue' /* webpackChunkName: "pages/about-us/organization-chart/create" */))
const _e4fd706a = () => interopDefault(import('../pages/about-us/slider/create.vue' /* webpackChunkName: "pages/about-us/slider/create" */))
const _0defeb38 = () => interopDefault(import('../pages/about-us/vision/create.vue' /* webpackChunkName: "pages/about-us/vision/create" */))
const _b1f71252 = () => interopDefault(import('../pages/contact-us/faq/create.vue' /* webpackChunkName: "pages/contact-us/faq/create" */))
const _395fcd8b = () => interopDefault(import('../pages/contact-us/general-inquiry/create.vue' /* webpackChunkName: "pages/contact-us/general-inquiry/create" */))
const _684f5dc2 = () => interopDefault(import('../pages/contact-us/whistle-blower/create.vue' /* webpackChunkName: "pages/contact-us/whistle-blower/create" */))
const _fce9d310 = () => interopDefault(import('../pages/home/article-category/create.vue' /* webpackChunkName: "pages/home/article-category/create" */))
const _3bc322fa = () => interopDefault(import('../pages/home/article/create.vue' /* webpackChunkName: "pages/home/article/create" */))
const _2fb8de65 = () => interopDefault(import('../pages/home/menubar/create.vue' /* webpackChunkName: "pages/home/menubar/create" */))
const _c0ef02e8 = () => interopDefault(import('../pages/home/slider/create.vue' /* webpackChunkName: "pages/home/slider/create" */))
const _0a02b3a6 = () => interopDefault(import('../pages/internal-portal/lcm-internal-hr/create.vue' /* webpackChunkName: "pages/internal-portal/lcm-internal-hr/create" */))
const _19f45962 = () => interopDefault(import('../pages/internal-portal/user-management/system-security/index.vue' /* webpackChunkName: "pages/internal-portal/user-management/system-security/index" */))
const _3623c1f4 = () => interopDefault(import('../pages/internal-portal/user-management/user-info/index.vue' /* webpackChunkName: "pages/internal-portal/user-management/user-info/index" */))
const _b363a500 = () => interopDefault(import('../pages/internal-portal/user-management/user-type/index.vue' /* webpackChunkName: "pages/internal-portal/user-management/user-type/index" */))
const _422323ee = () => interopDefault(import('../pages/jobs/jobs/create.vue' /* webpackChunkName: "pages/jobs/jobs/create" */))
const _07c34c46 = () => interopDefault(import('../pages/join-us/career/create.vue' /* webpackChunkName: "pages/join-us/career/create" */))
const _62b19c78 = () => interopDefault(import('../pages/join-us/project-bidding/create.vue' /* webpackChunkName: "pages/join-us/project-bidding/create" */))
const _f12c207a = () => interopDefault(import('../pages/news/category/create.vue' /* webpackChunkName: "pages/news/category/create" */))
const _1d7d89ce = () => interopDefault(import('../pages/news/news/create.vue' /* webpackChunkName: "pages/news/news/create" */))
const _5dc16dca = () => interopDefault(import('../pages/policy-and-regulation/approved-license/create.vue' /* webpackChunkName: "pages/policy-and-regulation/approved-license/create" */))
const _0244983a = () => interopDefault(import('../pages/policy-and-regulation/policy/create.vue' /* webpackChunkName: "pages/policy-and-regulation/policy/create" */))
const _4ed3396b = () => interopDefault(import('../pages/policy-and-regulation/regulation/create.vue' /* webpackChunkName: "pages/policy-and-regulation/regulation/create" */))
const _93ee716c = () => interopDefault(import('../pages/internal-portal/user-management/user-info/create.vue' /* webpackChunkName: "pages/internal-portal/user-management/user-info/create" */))
const _642bbd8b = () => interopDefault(import('../pages/internal-portal/operator-hr-monitoring/view/employee/create.vue' /* webpackChunkName: "pages/internal-portal/operator-hr-monitoring/view/employee/create" */))
const _e24ad820 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _287042f8 = () => interopDefault(import('../pages/internal-portal/operator-hr-monitoring/view/employee/edit/_id.vue' /* webpackChunkName: "pages/internal-portal/operator-hr-monitoring/view/employee/edit/_id" */))
const _aa387950 = () => interopDefault(import('../pages/home/menubar/sub-menubar/create/_id.vue' /* webpackChunkName: "pages/home/menubar/sub-menubar/create/_id" */))
const _3b8454a6 = () => interopDefault(import('../pages/home/menubar/sub-menubar/edit/_id.vue' /* webpackChunkName: "pages/home/menubar/sub-menubar/edit/_id" */))
const _53086b1e = () => interopDefault(import('../pages/internal-portal/operator-hr-monitoring/view/documents/_id.vue' /* webpackChunkName: "pages/internal-portal/operator-hr-monitoring/view/documents/_id" */))
const _41516a8a = () => interopDefault(import('../pages/internal-portal/operator-hr-monitoring/view/employee/_id.vue' /* webpackChunkName: "pages/internal-portal/operator-hr-monitoring/view/employee/_id" */))
const _3a897483 = () => interopDefault(import('../pages/internal-portal/user-management/user-info/edit/_id.vue' /* webpackChunkName: "pages/internal-portal/user-management/user-info/edit/_id" */))
const _2e0e0759 = () => interopDefault(import('../pages/home/menubar/sub-menubar/_id.vue' /* webpackChunkName: "pages/home/menubar/sub-menubar/_id" */))
const _686730a6 = () => interopDefault(import('../pages/internal-portal/lcm-internal-hr/detail/_id.vue' /* webpackChunkName: "pages/internal-portal/lcm-internal-hr/detail/_id" */))
const _198326a6 = () => interopDefault(import('../pages/internal-portal/lcm-internal-hr/edit/_id.vue' /* webpackChunkName: "pages/internal-portal/lcm-internal-hr/edit/_id" */))
const _327f2ac4 = () => interopDefault(import('../pages/internal-portal/operator-financial-report/view-report/_id.vue' /* webpackChunkName: "pages/internal-portal/operator-financial-report/view-report/_id" */))
const _2d012e48 = () => interopDefault(import('../pages/internal-portal/operator-hr-monitoring/view/_id.vue' /* webpackChunkName: "pages/internal-portal/operator-hr-monitoring/view/_id" */))
const _ca791578 = () => interopDefault(import('../pages/internal-portal/user-management/change-password/_id.vue' /* webpackChunkName: "pages/internal-portal/user-management/change-password/_id" */))
const _1aece030 = () => interopDefault(import('../pages/internal-portal/user-management/user-type/_id.vue' /* webpackChunkName: "pages/internal-portal/user-management/user-type/_id" */))
const _297f3827 = () => interopDefault(import('../pages/jobs/jobs/edit/_id.vue' /* webpackChunkName: "pages/jobs/jobs/edit/_id" */))
const _d050b3f2 = () => interopDefault(import('../pages/news/news/edit/_id.vue' /* webpackChunkName: "pages/news/news/edit/_id" */))
const _61b7e87b = () => interopDefault(import('../pages/about-us/slider/_id.vue' /* webpackChunkName: "pages/about-us/slider/_id" */))
const _6c1df4ef = () => interopDefault(import('../pages/contact-us/faq/_id.vue' /* webpackChunkName: "pages/contact-us/faq/_id" */))
const _5de13e24 = () => interopDefault(import('../pages/home/article-category/_id.vue' /* webpackChunkName: "pages/home/article-category/_id" */))
const _5872fcc3 = () => interopDefault(import('../pages/home/article/_id.vue' /* webpackChunkName: "pages/home/article/_id" */))
const _4f276821 = () => interopDefault(import('../pages/home/menubar/_id.vue' /* webpackChunkName: "pages/home/menubar/_id" */))
const _61b5dd4c = () => interopDefault(import('../pages/home/slider/_id.vue' /* webpackChunkName: "pages/home/slider/_id" */))
const _56251b8a = () => interopDefault(import('../pages/internal-portal/user-management/_id.vue' /* webpackChunkName: "pages/internal-portal/user-management/_id" */))
const _19c1fa7e = () => interopDefault(import('../pages/jobs/applied/_id.vue' /* webpackChunkName: "pages/jobs/applied/_id" */))
const _58413738 = () => interopDefault(import('../pages/jobs/jobs/_id.vue' /* webpackChunkName: "pages/jobs/jobs/_id" */))
const _7f485fa9 = () => interopDefault(import('../pages/join-us/career/_id.vue' /* webpackChunkName: "pages/join-us/career/_id" */))
const _5f1de922 = () => interopDefault(import('../pages/join-us/project-bidding/_id.vue' /* webpackChunkName: "pages/join-us/project-bidding/_id" */))
const _70790383 = () => interopDefault(import('../pages/news/category/_id.vue' /* webpackChunkName: "pages/news/category/_id" */))
const _42ce9adc = () => interopDefault(import('../pages/policy-and-regulation/approved-license/_id.vue' /* webpackChunkName: "pages/policy-and-regulation/approved-license/_id" */))
const _6098b363 = () => interopDefault(import('../pages/policy-and-regulation/policy/_id.vue' /* webpackChunkName: "pages/policy-and-regulation/policy/_id" */))
const _16374a4a = () => interopDefault(import('../pages/policy-and-regulation/regulation/_id.vue' /* webpackChunkName: "pages/policy-and-regulation/regulation/_id" */))
const _6a6b11d0 = () => interopDefault(import('../pages/admin/_id.vue' /* webpackChunkName: "pages/admin/_id" */))
const _6abec956 = () => interopDefault(import('../pages/language/_id.vue' /* webpackChunkName: "pages/language/_id" */))
const _bec43266 = () => interopDefault(import('../pages/permissions/_id.vue' /* webpackChunkName: "pages/permissions/_id" */))
const _2808de77 = () => interopDefault(import('../pages/projects/_id.vue' /* webpackChunkName: "pages/projects/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _71f208b0,
    name: "admin"
  }, {
    path: "/contact-us",
    component: _09c8fb80,
    name: "contact-us"
  }, {
    path: "/internal-portal",
    component: _49756f1d,
    name: "internal-portal"
  }, {
    path: "/language-strings",
    component: _46490722,
    name: "language-strings"
  }, {
    path: "/login",
    component: _58823707,
    name: "login"
  }, {
    path: "/my-profile",
    component: _b510614c,
    name: "my-profile"
  }, {
    path: "/office-website-cms",
    component: _5eeff84a,
    name: "office-website-cms"
  }, {
    path: "/permissions",
    component: _20a85f25,
    name: "permissions"
  }, {
    path: "/users",
    component: _6cb34029,
    name: "users"
  }, {
    path: "/about-us/about",
    component: _40145cf1,
    name: "about-us-about"
  }, {
    path: "/about-us/mission",
    component: _625e09f0,
    name: "about-us-mission"
  }, {
    path: "/about-us/organization-chart",
    component: _484730f6,
    name: "about-us-organization-chart"
  }, {
    path: "/about-us/vision",
    component: _0f02b39a,
    name: "about-us-vision"
  }, {
    path: "/admin/create",
    component: _17f94fe4,
    name: "admin-create"
  }, {
    path: "/contact-us/faq",
    component: _0c5d6cc7,
    name: "contact-us-faq"
  }, {
    path: "/contact-us/general-inquiry",
    component: _42a26f93,
    name: "contact-us-general-inquiry"
  }, {
    path: "/contact-us/whistle-blower",
    component: _47fdad02,
    name: "contact-us-whistle-blower"
  }, {
    path: "/home/article",
    component: _378fcf9b,
    name: "home-article"
  }, {
    path: "/home/article-category",
    component: _0ab855f4,
    name: "home-article-category"
  }, {
    path: "/home/menubar",
    component: _52d6db79,
    name: "home-menubar"
  }, {
    path: "/home/slider",
    component: _6be1cb1c,
    name: "home-slider"
  }, {
    path: "/internal-portal/lcm-internal-hr",
    component: _1754fab1,
    name: "internal-portal-lcm-internal-hr"
  }, {
    path: "/internal-portal/operator-financial-report",
    component: _336a7dcf,
    name: "internal-portal-operator-financial-report"
  }, {
    path: "/internal-portal/operator-hr-monitoring",
    component: _1f47ebec,
    name: "internal-portal-operator-hr-monitoring"
  }, {
    path: "/internal-portal/user-activity",
    component: _3ba18c1f,
    name: "internal-portal-user-activity"
  }, {
    path: "/internal-portal/user-management",
    component: _7f42c713,
    name: "internal-portal-user-management"
  }, {
    path: "/join-us/career",
    component: _02cfddfe,
    name: "join-us-career"
  }, {
    path: "/join-us/project-bidding",
    component: _3f31203a,
    name: "join-us-project-bidding"
  }, {
    path: "/language-strings/create",
    component: _aae7b048,
    name: "language-strings-create"
  }, {
    path: "/language/create",
    component: _2633229e,
    name: "language-create"
  }, {
    path: "/news/news",
    component: _7b8a9ef0,
    name: "news-news"
  }, {
    path: "/permissions/create",
    component: _1c17d039,
    name: "permissions-create"
  }, {
    path: "/policy-and-regulation/approved-license",
    component: _0d076918,
    name: "policy-and-regulation-approved-license"
  }, {
    path: "/policy-and-regulation/policy",
    component: _65bb438a,
    name: "policy-and-regulation-policy"
  }, {
    path: "/policy-and-regulation/regulation",
    component: _7d2207b3,
    name: "policy-and-regulation-regulation"
  }, {
    path: "/projects/create",
    component: _55fa7f62,
    name: "projects-create"
  }, {
    path: "/users/create",
    component: _51690fb5,
    name: "users-create"
  }, {
    path: "/about-us/about/update",
    component: _f229158c,
    name: "about-us-about-update"
  }, {
    path: "/about-us/mission/create",
    component: _11177ece,
    name: "about-us-mission-create"
  }, {
    path: "/about-us/organization-chart/create",
    component: _2f598ef0,
    name: "about-us-organization-chart-create"
  }, {
    path: "/about-us/slider/create",
    component: _e4fd706a,
    name: "about-us-slider-create"
  }, {
    path: "/about-us/vision/create",
    component: _0defeb38,
    name: "about-us-vision-create"
  }, {
    path: "/contact-us/faq/create",
    component: _b1f71252,
    name: "contact-us-faq-create"
  }, {
    path: "/contact-us/general-inquiry/create",
    component: _395fcd8b,
    name: "contact-us-general-inquiry-create"
  }, {
    path: "/contact-us/whistle-blower/create",
    component: _684f5dc2,
    name: "contact-us-whistle-blower-create"
  }, {
    path: "/home/article-category/create",
    component: _fce9d310,
    name: "home-article-category-create"
  }, {
    path: "/home/article/create",
    component: _3bc322fa,
    name: "home-article-create"
  }, {
    path: "/home/menubar/create",
    component: _2fb8de65,
    name: "home-menubar-create"
  }, {
    path: "/home/slider/create",
    component: _c0ef02e8,
    name: "home-slider-create"
  }, {
    path: "/internal-portal/lcm-internal-hr/create",
    component: _0a02b3a6,
    name: "internal-portal-lcm-internal-hr-create"
  }, {
    path: "/internal-portal/user-management/system-security",
    component: _19f45962,
    name: "internal-portal-user-management-system-security"
  }, {
    path: "/internal-portal/user-management/user-info",
    component: _3623c1f4,
    name: "internal-portal-user-management-user-info"
  }, {
    path: "/internal-portal/user-management/user-type",
    component: _b363a500,
    name: "internal-portal-user-management-user-type"
  }, {
    path: "/jobs/jobs/create",
    component: _422323ee,
    name: "jobs-jobs-create"
  }, {
    path: "/join-us/career/create",
    component: _07c34c46,
    name: "join-us-career-create"
  }, {
    path: "/join-us/project-bidding/create",
    component: _62b19c78,
    name: "join-us-project-bidding-create"
  }, {
    path: "/news/category/create",
    component: _f12c207a,
    name: "news-category-create"
  }, {
    path: "/news/news/create",
    component: _1d7d89ce,
    name: "news-news-create"
  }, {
    path: "/policy-and-regulation/approved-license/create",
    component: _5dc16dca,
    name: "policy-and-regulation-approved-license-create"
  }, {
    path: "/policy-and-regulation/policy/create",
    component: _0244983a,
    name: "policy-and-regulation-policy-create"
  }, {
    path: "/policy-and-regulation/regulation/create",
    component: _4ed3396b,
    name: "policy-and-regulation-regulation-create"
  }, {
    path: "/internal-portal/user-management/user-info/create",
    component: _93ee716c,
    name: "internal-portal-user-management-user-info-create"
  }, {
    path: "/internal-portal/operator-hr-monitoring/view/employee/create",
    component: _642bbd8b,
    name: "internal-portal-operator-hr-monitoring-view-employee-create"
  }, {
    path: "/",
    component: _e24ad820,
    name: "index"
  }, {
    path: "/internal-portal/operator-hr-monitoring/view/employee/edit/:id",
    component: _287042f8,
    name: "internal-portal-operator-hr-monitoring-view-employee-edit-id"
  }, {
    path: "/home/menubar/sub-menubar/create/:id?",
    component: _aa387950,
    name: "home-menubar-sub-menubar-create-id"
  }, {
    path: "/home/menubar/sub-menubar/edit/:id?",
    component: _3b8454a6,
    name: "home-menubar-sub-menubar-edit-id"
  }, {
    path: "/internal-portal/operator-hr-monitoring/view/documents/:id?",
    component: _53086b1e,
    name: "internal-portal-operator-hr-monitoring-view-documents-id"
  }, {
    path: "/internal-portal/operator-hr-monitoring/view/employee/:id?",
    component: _41516a8a,
    name: "internal-portal-operator-hr-monitoring-view-employee-id"
  }, {
    path: "/internal-portal/user-management/user-info/edit/:id",
    component: _3a897483,
    name: "internal-portal-user-management-user-info-edit-id"
  }, {
    path: "/home/menubar/sub-menubar/:id?",
    component: _2e0e0759,
    name: "home-menubar-sub-menubar-id"
  }, {
    path: "/internal-portal/lcm-internal-hr/detail/:id?",
    component: _686730a6,
    name: "internal-portal-lcm-internal-hr-detail-id"
  }, {
    path: "/internal-portal/lcm-internal-hr/edit/:id?",
    component: _198326a6,
    name: "internal-portal-lcm-internal-hr-edit-id"
  }, {
    path: "/internal-portal/operator-financial-report/view-report/:id?",
    component: _327f2ac4,
    name: "internal-portal-operator-financial-report-view-report-id"
  }, {
    path: "/internal-portal/operator-hr-monitoring/view/:id?",
    component: _2d012e48,
    name: "internal-portal-operator-hr-monitoring-view-id"
  }, {
    path: "/internal-portal/user-management/change-password/:id?",
    component: _ca791578,
    name: "internal-portal-user-management-change-password-id"
  }, {
    path: "/internal-portal/user-management/user-type/:id?",
    component: _1aece030,
    name: "internal-portal-user-management-user-type-id"
  }, {
    path: "/jobs/jobs/edit/:id?",
    component: _297f3827,
    name: "jobs-jobs-edit-id"
  }, {
    path: "/news/news/edit/:id?",
    component: _d050b3f2,
    name: "news-news-edit-id"
  }, {
    path: "/about-us/slider/:id?",
    component: _61b7e87b,
    name: "about-us-slider-id"
  }, {
    path: "/contact-us/faq/:id",
    component: _6c1df4ef,
    name: "contact-us-faq-id"
  }, {
    path: "/home/article-category/:id",
    component: _5de13e24,
    name: "home-article-category-id"
  }, {
    path: "/home/article/:id",
    component: _5872fcc3,
    name: "home-article-id"
  }, {
    path: "/home/menubar/:id",
    component: _4f276821,
    name: "home-menubar-id"
  }, {
    path: "/home/slider/:id",
    component: _61b5dd4c,
    name: "home-slider-id"
  }, {
    path: "/internal-portal/user-management/:id",
    component: _56251b8a,
    name: "internal-portal-user-management-id"
  }, {
    path: "/jobs/applied/:id?",
    component: _19c1fa7e,
    name: "jobs-applied-id"
  }, {
    path: "/jobs/jobs/:id?",
    component: _58413738,
    name: "jobs-jobs-id"
  }, {
    path: "/join-us/career/:id?",
    component: _7f485fa9,
    name: "join-us-career-id"
  }, {
    path: "/join-us/project-bidding/:id?",
    component: _5f1de922,
    name: "join-us-project-bidding-id"
  }, {
    path: "/news/category/:id?",
    component: _70790383,
    name: "news-category-id"
  }, {
    path: "/policy-and-regulation/approved-license/:id?",
    component: _42ce9adc,
    name: "policy-and-regulation-approved-license-id"
  }, {
    path: "/policy-and-regulation/policy/:id?",
    component: _6098b363,
    name: "policy-and-regulation-policy-id"
  }, {
    path: "/policy-and-regulation/regulation/:id?",
    component: _16374a4a,
    name: "policy-and-regulation-regulation-id"
  }, {
    path: "/admin/:id",
    component: _6a6b11d0,
    name: "admin-id"
  }, {
    path: "/language/:id?",
    component: _6abec956,
    name: "language-id"
  }, {
    path: "/permissions/:id",
    component: _bec43266,
    name: "permissions-id"
  }, {
    path: "/projects/:id?",
    component: _2808de77,
    name: "projects-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
